.main-label {
  border: 1px solid #2b3467;
  border-radius: 0.5em;
}

.service-label {
  border: 1px solid #2b3467;
  border-radius: 0.5em;
}

.dashboard-container {
  border: 1px solid #2b3467;
  border-radius: 1em;
}

.service {
  border: 1px solid #2b3467;
  border-radius: 1em;
}

.table-content {
  border: 1px solid #2b3467;
  border-radius: 1em;
}
