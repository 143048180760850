/* box shadow to be implemented to container desktop
instead of left and right */
@media only screen and (min-width: 576px) {
    .shadow-larger {
        padding: 5em!important;
        border-radius: 1rem 1rem 1rem 1rem;
        box-shadow: rgba(0, 0, 0, 0.1) 4em 4em 10em, rgba(0, 0, 0, 0.15) 5em 10em 50em;
    }
}

/* form .form-control:focus {
    border-color: #2B3467;
    box-shadow: none;
} */

.forgot-password-form {
    min-width: 288px;
}