:root {
  --variable-collection-wesystems-charcoal-100: rgba(54, 69, 79, 1);
  --variable-collection-wesystems-charcoal-50: rgba(54, 69, 79, 0.5);
}

.voice-msg {
  align-items: center;
  border: 3px solid;
  border-color: var(--variable-collection-wesystems-charcoal-100);
  border-radius: 20px;
  display: inline-flex;
  gap: 40px;
  padding: 30px 40px;
  position: relative;
}

.voice-msg .btns {
  flex: 0 0 auto;
  position: relative;
}

.voice-msg .msg {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  position: relative;
}

.voice-msg .rectangle-1 {
  border-radius: 20px;
  height: 5px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-2 {
  border-radius: 20px;
  height: 10px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-3 {
  border-radius: 20px;
  height: 15px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-4 {
  border-radius: 20px;
  height: 20px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-5 {
  border-radius: 20px;
  height: 25px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-6 {
  border-radius: 20px;
  height: 30px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-7 {
  border-radius: 20px;
  height: 35px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-8 {
  border-radius: 20px;
  height: 40px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-9 {
  border-radius: 20px;
  height: 45px;
  position: relative;
  width: 5px;
}

/* origin */

.voice-msg .rectangle-10 {
  border-radius: 20px;
  height: 40px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-11 {
  border-radius: 20px;
  height: 35px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-12 {
  border-radius: 20px;
  height: 30px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-13 {
  border-radius: 20px;
  height: 25px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-14 {
  border-radius: 20px;
  height: 30px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-15 {
  border-radius: 20px;
  height: 35px;
  position: relative;
  width: 5px;
}
.voice-msg .rectangle-16 {
  border-radius: 20px;
  height: 40px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-17 {
  border-radius: 20px;
  height: 45px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-18 {
  border-radius: 20px;
  height: 40px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-19 {
  border-radius: 20px;
  height: 35px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-20 {
  border-radius: 20px;
  height: 30px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-21 {
  border-radius: 20px;
  height: 25px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-22 {
  border-radius: 20px;
  height: 20px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-23 {
  border-radius: 20px;
  height: 15px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-24 {
  border-radius: 20px;
  height: 10px;
  position: relative;
  width: 5px;
}

.voice-msg .rectangle-25 {
  border-radius: 20px;
  height: 5px;
  position: relative;
  width: 5px;
}

.rectangles {
  transition: background-color 0.3s ease;
  transform: translateZ(0);
}

@media (max-width: 500px) {
  .voice-msg {
    align-items: center;
    border: 3px solid;
    border-color: var(--variable-collection-wesystems-charcoal-100);
    border-radius: 20px;
    display: inline-flex;
    gap: 40px;
    padding: 15px 15px;
    position: relative;
    width: 400px;
  }
}
