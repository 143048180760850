.sidebar--open {
  width: 220px;
}

.sidebar--closed {
  width: 44px;
  z-index: 2;
}

.sidebar--open .sidebar-position span {
  display: block;
  height: auto;
}

.navtext--closed {
  display: none;
  transition: 0.6s ease-in-out;
}

/* .gradients {
  width: 100%;
  height: 100vh !important;
  background-image: linear-gradient(
      185deg,
      rgba(44, 53, 104, 1) 0%,
      rgba(44, 53, 104, 0.49740250103947825) 25%,
      rgba(44, 53, 104, 0.30132406966692926) 50%,
      rgba(44, 53, 104, 0.15006356546524857) 75%,
      rgba(44, 53, 104, 0) 100%
    ),
    linear-gradient(
      145deg,
      #2c3568 0%,
      rgba(49, 59, 116, 0.5) 28.1%,
      rgba(57, 69, 135, 0.2) 85.97%,
      rgba(87, 105, 206, 0) 114.39%
    ),
    linear-gradient(
      90deg,
      rgba(44, 53, 104, 0.75) 0%,
      rgba(44, 53, 104, 0.56) 24.7%,
      rgba(44, 53, 104, 0.08) 74.8%,
      rgba(44, 53, 104, 0) 100%
    ),
    url(/public/images/mountains.png);
  background-repeat: no-repeat !important;
  background-size: cover !important;
} */
