.license-container {
  width: 100%;
  border-radius: 1.25rem;
  border: 1px solid var(--bs-primary);
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.license-card-container {
  border-radius: 0.75rem;
  border: 1px solid var(--bs-primary);
  background: var(--bs-white);
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.license-card-format {
  width: 100%;
  max-height: fit-content;
}

.license-h-font {
  font-size: 1.4rem;
  font-weight: 500;
}
