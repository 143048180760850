.side-nav-link-container {
  min-height: 35px;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  color: var(--link-color);
}

.side-nav-link-container:hover {
  color: var(--link-hover-color) !important;
  fill: var(--link-hover-color) !important;
  background-color: var(--hover-bg-color) !important;
}

.side-nav-link-container:hover::before,
.side-nav-link-container.active::before {
  background-color: var(--vertical-line-color);
}

.side-nav-link-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.side-nav-link-icon {
  height: 20px;
  width: 20px;
  color: var(--icon-color) !important;
  fill: var(--icon-color) !important;
  transition: color 0.3s;
}

.side-nav-link:hover .side-nav-link-icon {
  color: var(--icon-hover-color) !important;
}

.side-nav-link.active {
  background-color: var(--active-bg-color) !important;
  color: var(--link-active-color) !important;
  fill: var(--link-active-color) !important;
}

.side-nav-link.active .side-nav-link-icon {
  color: var(--icon-active-color) !important;
}

/* <------------tooltip ----------> */

.side-nav-link .tooltip-text {
  visibility: hidden;
  position: absolute;
  margin-left: 40px;
  background-color: var(--bs-dark);
  color: var(--bs-white);
  z-index: 1;
  pointer-events: none;
  padding: 0.35rem 1rem;
  border-radius: 0.25em;
  font-size: small;
}

.side-nav-link:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text::before {
  position: absolute;
  content: "" !important;
  z-index: 10 !important;
  border: 0.75em solid transparent;
  border-right-color: var(--bs-dark);
  transform: translateX(calc(-100% - 1.2em));
}

.side-nav-link .tooltip-text-open {
  visibility: hidden;
  position: absolute;
  margin-left: 216px;
  background-color: var(--bs-dark) !important;
  color: var(--bs-white);
  z-index: 1;
  pointer-events: none;
  padding: 0.35rem 1rem;
  border-radius: 0.25em;
  font-size: small;
}

.side-nav-link:hover .tooltip-text-open {
  visibility: visible;
}

.tooltip-text-open::before {
  position: absolute;
  content: "" !important;
  z-index: 10 !important;
  border: 0.75em solid transparent;
  border-right-color: var(--bs-dark);
  transform: translateX(calc(-100% - 1.2em));
}
