.overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bs-gray-800);
  z-index: 4;
  display: none;
  animation-duration: 0.3s;
  animation-name: fadeIn;
}

.overlay.show {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX();
  }
  to {
    opacity: 1;
    transform: translateX();
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

.button-close {
  position: absolute !important;
  top: 1rem !important;
  right: 1rem !important;
  z-index: 5;
  color: var(--bs-white) !important;
  background-color: var(--bs-primary) !important;
  border: solid 1px var(--bs-white) !important;
  border-radius: 50% !important;
}

.button-close:hover {
  border: 1px solid var(--bs-secondary) !important;
  background-color: var(--bs-white) !important;
  transition: 0.3s ease-in-out;
  color: red;
}

.button-close :focus {
  border: 2px solid var(--bs-secondary) !important;
  background-color: var(--bs-dark) !important;
  color: var(--bs-white) !important;
}

.button-close > .closeX {
  margin: 0.4rem 0 0.4rem 0;
}

.closeX:hover {
  color: var(--bs-dark) !important;
}
