input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #2b3467;
  border-radius: 5px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #862f65;
  border-radius: 5px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
  background: #fff;
  border: 6px solid #2b3467;
  border-radius: 100%;
  cursor: pointer;
  margin-top: -3px;
}

input[type="range"]::-moz-range-thumb {
  width: 14px;
  height: 14px;
  background: #fff;
  border: 2px solid #8c8c8c;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  width: 14px;
  height: 14px;
  background: #fff;
  border: 2px solid #8c8c8c;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #2b3467;
}

input[type="range"]:focus::-moz-range-track {
  background: #8c8c8c;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #2b3467;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #ddd;
}
