.customer-edit-button {
    min-width: 150px;
}

.customer-select-flag {
    border: 1px solid #2b3467;
    border-radius: 0.5em;
}

.customer-label-size {
    min-width: 25%;
}