
.customer-bar-font {
color: #2b3467;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.customer-bar-font-h3 {
color: #2b3467;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}