.side-nav-button {
  min-height: 35px;
}

.side-nav-button-conainer {
  min-height: 35px;
}

.side-nav-button-conainer:hover {
  background-color: rgba(18, 35, 46, 0.5) !important;
}

/* <--------tooltip  --------->*/

.side-nav-button:hover .tooltip-text-button {
  visibility: visible;
}

.tooltip-text-button::before {
  position: absolute;
  content: "" !important;
  z-index: 10 !important;
  border: 0.75em solid transparent;
  border-right-color: #102431;
  transform: translateX(calc(-100% - 1.2em));
}

.side-nav-button .tooltip-text-button {
  visibility: hidden;
  position: absolute;
  margin-left: 49px;
  background-color: var(--bs-dark);
  color: var(--bs-white);
  z-index: 1;
  pointer-events: none;
  padding: 0.35rem 1rem;
  border-radius: 0.25em;
  font-size: small;
}

.side-nav-button .tooltip-text-button-open {
  visibility: hidden;
  position: absolute;
  margin-left: 224px;
  background-color: var(--bs-dark);
  color: var(--bs-white);
  z-index: 1;
  pointer-events: none;
  padding: 0.35rem 1rem;
  border-radius: 0.25em;
  font-size: small;
}

.side-nav-button:hover .tooltip-text-button-open {
  visibility: visible;
}

.tooltip-text-button-open::before {
  position: absolute;
  content: "" !important;
  z-index: 10 !important;
  border: 0.75em solid transparent;
  border-right-color: var(--bs-dark);
  transform: translateX(calc(-100% - 1.2em));
}
