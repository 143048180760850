.dds-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: .5em;
    padding: 0 .65em 0 .65em;
    border-radius: .25em;
    outline: none;
}

.dds-container:focus {
    border-color: hsl(200, 100%, 50%);
}

.dds-clear-btn {
    background: none;
    color: #777;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.25em;
    transition: .3s all;
}

.dds-clear-btn:focus,
.dds-clear-btn:hover {
    color: #333;
    font-size: 1.35em;
}

.dds-divider {
    background-color: #777;
    align-self: stretch;
    width: .00em;
}

.dds-caret {
    border: .30em solid transparent;
    cursor: pointer;
    transition: .3s all;
}

.dds-caret_open {
    translate: 0 25%;
    border-top-color: #777;
}

.dds-caret_close {
    translate: 0 -25%;
    border-bottom-color: #777;
}

.dds-options {
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    max-height: 15em;
    overflow-y: auto;
    border: .05em solid #777;
    border-radius: .3;
    width: 100%;
    left: 0;
    top: calc(100% + .25em);
    background-color: white;
    z-index: 100;
}

.dds-show {
    display: block;
}

.dds-option {
    padding: .25em .5em;
    cursor: pointer;
}

.dds-value {
    flex-grow: 1;
    display: flex;
    gap: .5em;
    flex-wrap: wrap;
}

.dds-option.selected {
    background-color: hsl(200, 100%, 70%);
}

.dds-option.highlighted {
    background-color: hsl(200, 100%, 50%);
    color: white
}

.dds-option_badge {
    display: flex;
    align-items: center;
    border: .05em solid #7d5cb6;
    border-radius: .25em;
    padding: .15em .25em;
    gap: .25em;
    cursor: pointer;
    background: none;
    outline: none;
    max-height: 25px;
}

.dds-option_badge:hover,
.dds-option_badge:focus {
    background-color: hsl(0, 76%, 97%);
    border-color: hsl(0, 100%, 50%);
}

.dds-option_badge:hover>.remove-btn,
.dds-option_badge:focus>.remove-btn {
    color: hsl(0, 100%, 50%);
}

.dds-option_badge>.remove-btn {
    font-size: 1.25em;
    color: #777;
}