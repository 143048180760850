.groups-label-size {
  min-width: 150px;
}

.groups-input-size {
  min-width: 250px;
}

.groups-edit-button {
  min-width: 150px;
}

.group-filter-button {
  min-width: 100px;
}

