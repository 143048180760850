.container-mobile {
    border-radius: 1rem;
}

.container-desktop {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.container-text {
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
}

/* box shadow to be implemented to container desktop
instead of left and right */
.larger {
    border-radius: 1rem 1rem 1rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 4em 4em 10em, rgba(0, 0, 0, 0.15) 5em 10em 50em;
}


.container-left {
    width: 60%!important;
}

.container-right {
    width: 40%!important;
    border-radius: 0rem 1rem 1rem 0rem;
}

.img-people {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.img-logo {
    object-fit: none;
}

.h2 {
    margin-bottom: 1rem;
    padding-left: .5rem;
}

.h5 {
    padding-left: .5rem;
    text-align: center;
    text-decoration: underline;
}

::placeholder {
    padding-left: 0.25em;
}

input:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity .5s;
}

/* forgor password underline effect */
.underline {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #2B3467;
}


.underline::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #2B3467;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .underline:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
