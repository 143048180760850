.main-header {
  font-size: 2.1em;
  font-weight: 700;
}

.main-paragraph {
  font-weight: 600;
}

.faq-btn:hover {
  color: var(--bs-white) !important;
  fill: var(--bs-white) !important;
}

.manuals-row-subheader {
  font-size: 1em;
  font-weight: 700;
}

.paragraph {
  font-size: 0.9em;
}
