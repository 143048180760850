.side-nav-cat-container {
  min-height: 35px;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  color: var(--link-color);
}
.side-nav-cat-container:hover {
  color: var(--link-hover-color) !important;
  fill: var(--link-hover-color) !important;
  background-color: var(--hover-bg-color) !important;
}

.side-nav-cat-container:hover::before,
.side-nav-cat-container.active::before {
  background-color: var(--vertical-line-color);
}

.side-nav-cat-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.link-of-navcat:hover .side-nav-link-icon {
  color: var(--icon-hover-color) !important;
}

.link-of-navcat.active {
  background-color: var(--active-bg-color) !important;
  color: var(--link-active-color) !important;
  fill: var(--link-active-color) !important;
}

.link-of-navcat.active .side-nav-link-icon {
  color: var(--icon-active-color) !important;
}


.chevron-icon-fill {
  fill: var(--bs-white) !important;
}

/* Chevron up and down animation */

@keyframes rotate-up {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate-down {
  0% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.chevron-up {
  -webkit-animation-name: rotate-up;
  animation-name: rotate-up;
  animation-duration: 0.3s;
}

.chevron-down {
  -webkit-animation-name: rotate-down;
  animation-name: rotate-down;
  animation-duration: 0.3s;
}

/* Chevron up and down animation end */
