.hover-menu {
  background-color: var(--bs-primary);
  margin-left: 44px;
  position: absolute;
  border-radius: 0 7px 7px 0;
  min-width: 165px;
  z-index: 3;
}

.hover-menu-name {
  background-color: var(--bs-gray-500) !important;
  color: var(--bs-white) !important;
  padding-top: 7px;
  padding-bottom: 7px;
}

.hover-menu-link:hover {
  color: var(--bs-white) !important;
}
