.monitor-card-format {
    width: 100%;
    max-height: fit-content;
}

.monitor-h-font {
    font-size: 1.4rem;
    font-weight: 500;
}

.monitor-select {
    max-width: 300px;
    height: 100px;
}

.monitor-input {
    max-width: 300px;
}

.monitor-button {
    min-width: 150px;
    height: auto;
}

.monitor-checkbox {
    min-width: 300px;
    max-width: 300px;
    height: auto;
}