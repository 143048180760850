.card {
  margin: 15px 0px;
}
.Dashboard-header {
  padding: 15px;
  border: 1px solid black;
  border-radius: 15px;
}
.Dashboard-h1 {
  color: #2b3467;
  font-size: 24px;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Dashboard-body-h1 {
  margin-top: 10px;
  margin-left: 10px;
  color: #2b3467;
  font-size: 24px;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Dashboard-table-header {
  color: #2b3467;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard-icon {
  height: 80px;
  width: 80px;
}
