.side-nav-logo {
  min-height: 70px;
}

.side-nav-list {
  list-style-type: none;
  padding-top: 35px;
}

.side-nav-image {
  height: 45px;
}
