.authentication-digit-input {
    width: 1em !important;
    line-height: 1 !important;
    margin: .1em;
    padding: 8px !important;
    font-size: 2.55em !important;
    border: 1px solid #2B3467 !important;
    color: #2B3467 !important;
    border-radius: 0.2em !important;
    caret-color: transparent;
}

.authentication-digit-input::placeholder {
    position: absolute;
    left: 0;
}

.img-auth-icon {
    width: 100px;
    height: auto;
}

