.text-container {
  display: inline-block;
}
.mobileTxt {
  display: none;
}

@media all and (max-width: 576px) {
  .change-password-form {
    max-width: 288px;
  }
  .descTxt {
    display: none;
  }
  .mobileTxt {
    display: block;
  }
}
