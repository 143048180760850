.phone-numbers-select-button {
  border: 1px solid #2b3467;
  border-radius: 0.5em;
  height: 2.5rem;
}

.phone-numbers-filter-button {
  min-width: 150px;
}

.phone-numbers-buttons {
  min-width: 120px;
}

.main-box-layout {
  width: 100%;
  height: 50rem;
  flex-shrink: 0;
}

.main-box-container {
  border-radius: 1.6rem;
  border: 1px solid #2b3467;
  background: #fafafa;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.main-box-text {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

.phoneNumber-bar-font {
  font-size: 1.3rem;
  font-weight: 500;
}

.labelForm {
  min-width: 25%;
}

.siteLbl {
  padding-left: 10px;
  padding-right: 10px;
}
.labelComment {
  padding-right: 20px;
  color: #2b3467;
}
.labelCommentSpecial {
  padding-right: 42px;
}

.phone-numbers-icon {
  height: 15px;
  width: 15px;
}
