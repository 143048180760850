.playlists-cards {
  height: 70% !important;
}

.playlists-card {
  height: 100% !important;
  overflow-y: hidden !important;
  overflow-y: scroll !important;
}

.playlist-row {
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.playlist-row:hover {
  background-color: var(--bs-secondary);
  font-weight: 600;
}

.playlist-row.active {
  background-color: rgb(223, 220, 220) !important;
}

.playlist-row.playing {
  background-color: rgb(
    223,
    220,
    220
  ) !important; /* Light red background for playing track */
}
