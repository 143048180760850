.user-edit-label {
    min-width: fit-content;
    font-size: small;
}

.user-edit-username-label {
    padding-right: 20px;
}

.user-edit-password-label {
    padding-right: 22px;
}

.user-edit-role-label {
    padding-right: 54px;
}

.user-edit-email-label {
    padding-right: 49px;
}

.user-edit-button {
    min-width: 150px;
}

.user-label-size {
    min-width: 25%;
}