.bg-lightgray {
  background-color: #d9d9e1;
}

.bg-darkgray {
  background-color: #424242;
}

.left-body {
  z-index: 1;
  box-shadow: 5px;
  border-radius: 18px 0 0 18px;
  position: relative;
  margin-right: -12px;
  width: 450px;
  height: 500;
}

.right-body {
  position: relative;
  z-index: 3;
  border-radius: 18px 18px 18px 18px;
  width: 450px;
  height: 500px;
}

.underline::after {
  background-color: var(--bs-secondary);
}
#signup-link {
  text-decoration: none !important;
  border-bottom: 1px solid transparent !important;
  transition: border 0.25s ease-in-out !important;
  -webkit-transition: border 0.25s ease-in-out !important;
  -moz-transition: border 0.25s ease-in-out !important;
  -ms-transition: border 0.25s ease-in-out !important;
}

#signup-link:hover {
  border-bottom: 1px solid var(--bs-light) !important;
}
