
.mf-auth-container {
    width: 100% !important;
    height: fit-content !important;
    border-radius: 1.25rem;
    border: 1px solid #2B3467;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.mf-auth-container-text {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;
}

.mf-auth-input {
    max-width: 200px;
    height: 35px;
}

.mf-auth-button {
    min-width: 150px;
    height: 35px;
    border-top-right-radius: 0.5em !important;
    border-bottom-right-radius: 0.5em !important;
}

.img-logo {
    object-fit: none;
}