
.integration-card-format {
    width: 100%;
    max-height: fit-content;
}
    
.integration-h-font {
    font-size: 1.4rem;
    font-weight: 500;
}

.integration-button {
    min-width: 150px;
    height: auto;
}

.integration-input {
    max-width: 350px;
}

.integration-select {
    max-width: 345px;
    height: 100px;
}

.integration-password-label {
    padding-right: 27px;
}