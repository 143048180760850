.groupApp-edit-button {
  min-width: 150px;
}

.groupApp-label-size {
  /* min-width: 25%; */
  min-width: 72px;
}

.groupApp-input {
  min-width: 300px;
}
